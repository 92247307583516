import React from "react";
import { Collapse, Input, Label } from "reactstrap";
import RSSelect from "react-select";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { TooltipHint } from "./common";

const Checkbox = ({ id, name, value, onChange, children, size, disabled }) => (
  <div className={size ? `form-check form-check${size}` : "form-check"}>
    <input
      className="form-check-input"
      type="checkbox"
      id={id}
      name={name}
      checked={value}
      onChange={(e) => {
        onChange(name, e.target.checked);
      }}
      disabled={disabled}
    />
    <label htmlFor={id} className="form-check-label">
      {children}
    </label>
  </div>
);

const SimpleCheckbox = ({
  id,
  name,
  value,
  onChange,
  label,
  size,
  disabled,
}) => (
  <Checkbox
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    size={size}
    disabled={disabled}
  >
    {label}
  </Checkbox>
);

const customStyles = {
  control: () => ({
    width: "100%",
    height: "100%",
    textAlign: "left",
    display: "flex",
    flexWrap: "nowrap",
  }),
  container: (provided) => ({
    ...provided,
    background: "white",
    width: "initial",
  }),
};

const Select = ({
  options,
  placeholder,
  name,
  id,
  value,
  onChange,
  className = "form-select",
  readonly,
  disabled,
  onFocus,
  onBlur,
  required,
  noVirtualKeyboard,
  isInvalid,
}) => {
  const optionMap = React.useMemo(() => {
    const result = {};
    if (options) {
      options.forEach((o) => {
        result[o.value] = o;
      });
    }
    return result;
  }, [options]);

  return (
    <RSSelect
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      name={name}
      id={id}
      value={optionMap[value] || null}
      onChange={(newValue) => {
        onChange(name, newValue ? newValue.value : null);
      }}
      className={classnames(className, { "select-is-invalid": isInvalid })}
      required={required}
      isDisabled={readonly || disabled}
      onBlur={onBlur}
      onFocus={onFocus}
      inputMode={noVirtualKeyboard ? "none" : undefined}
      isSearchable={!noVirtualKeyboard}
    />
  );
};

const BaseCheckboxItem = ({
  LabelRenderer,
  opt,
  onChange,
  index,
  name,
  value,
}) => (
  <Checkbox
    name={`${name}_${opt.value}`}
    id={`id_${name}_${opt.value}`}
    value={value.includes(opt.value)}
    onChange={(cbName, cbValue) => {
      // delegate modified array, add or remove
      if (!cbValue) {
        onChange(
          name,
          value.filter((x) => x !== opt.value),
        );
      }
      if (cbValue) {
        const newValue = [...value];
        if (!newValue.includes(opt.value)) {
          newValue.push(opt.value);
        }
        onChange(name, newValue);
      }
    }}
  >
    <LabelRenderer option={opt} index={index} />
    {!LabelRenderer && opt.value}
  </Checkbox>
);

const CheckboxGroup = ({ options, LabelRenderer, name, value, onChange }) => {
  return options.map((opt, index) => (
    <BaseCheckboxItem
      key={opt.value}
      opt={opt}
      index={index}
      LabelRenderer={LabelRenderer}
      name={name}
      value={value}
      onChange={onChange}
    />
  ));
};

const ExpandableCheckboxGroup = ({
  options,
  LabelRenderer,
  name,
  value,
  onChange,
  collapsedCount = 5,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [leading, remaining] = React.useMemo(() => {
    const a = options.slice(0, collapsedCount);
    const b = options.slice(collapsedCount);
    return [a, b];
  }, [options, collapsedCount]);

  return (
    <>
      {leading.map((opt, index) => (
        <BaseCheckboxItem
          key={opt.value}
          opt={opt}
          index={index}
          value={value}
          onChange={onChange}
          LabelRenderer={LabelRenderer}
          name={name}
        />
      ))}
      {remaining && remaining.length > 0 ? (
        <Collapse isOpen={expanded}>
          {remaining.map((opt, index) => (
            <BaseCheckboxItem
              key={opt.value}
              opt={opt}
              index={index}
              value={value}
              onChange={onChange}
              LabelRenderer={LabelRenderer}
              name={name}
            />
          ))}
        </Collapse>
      ) : null}
      {remaining && remaining.length > 0 && !expanded ? (
        <button
          className="link-like-button sidebar-expand"
          type="button"
          onClick={() => setExpanded(true)}
        >
          <FormattedMessage id="controls.filter.show_all" />
        </button>
      ) : null}
    </>
  );
};

const StandardLabel = ({ label, id }) => (
  <Label className="form-label" for={id}>
    {label}
  </Label>
);

const LabelWithHint = ({ label, id, hint }) => (
  <div className="d-flex align-items-center justify-content-between">
    <StandardLabel label={label} id={id} />
    <TooltipHint triggerId={`tooltip_${id}`}>{hint}</TooltipHint>
  </div>
);

const TextInput = ({
  label,
  name,
  id,
  placeholder,
  onChange,
  value,
  defaultValue,
  type = "text",
  required,
  readonly,
  disabled,
  maxLength,
  hint,
  onBlur,
  className = "form-control",
  isInvalid,
  ...rest
}) => (
  <>
    {label && hint && <LabelWithHint label={label} id={id} hint={hint} />}
    {label && !hint && <StandardLabel label={label} id={id} />}
    <Input
      type={type}
      id={id}
      name={name}
      className={classnames(className, { "is-invalid": isInvalid })}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      required={required}
      readOnly={readonly}
      disabled={disabled}
      maxLength={maxLength}
      onBlur={onBlur}
      {...rest}
    />
  </>
);

const JumboCheckbox = ({
  children,
  id,
  name,
  checked,
  onChange,
  onDetailClick,
}) => (
  <div className="form-check delivery-radio">
    <input
      id={id}
      className="form-check-input"
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <Label className="form-check-label optional-extras" htmlFor={id}>
      {children}
    </Label>
    {onDetailClick && (
      <button
        className="btn btn-none detail-link d-block d-sm-none p-0"
        onClick={onDetailClick}
        type="button"
      >
        <span className="far fa-search text-muted" />
      </button>
    )}
  </div>
);

const JumboRadio = ({
  children,
  id,
  name,
  checked,
  onChange,
  itemValue,
  disabled,
}) => (
  <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4 mb-10 mb-sm-30">
    <div className="form-check delivery-radio mb-xl-0">
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.checked) {
            onChange(name, itemValue);
          }
        }}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  </div>
);

const AssignRating = ({ currentValue, onValueChanged }) => {
  const stars = [];
  for (let i = 1; i <= 5; i += 1) {
    stars.push(
      <button type="button" onClick={() => onValueChanged(i)} key={i}>
        <span
          className={`fas fa-star ${
            currentValue >= i ? "text-primary" : "text-grey"
          }`}
        />
      </button>,
    );
  }

  return (
    <div className="text-xxl assign-rating">
      <>{stars}</>
    </div>
  );
};

const TextArea = (props) => <TextInput type="textarea" {...props} />;

export {
  Checkbox,
  SimpleCheckbox,
  Select,
  CheckboxGroup,
  TextInput,
  JumboCheckbox,
  StandardLabel,
  JumboRadio,
  AssignRating,
  TextArea,
  ExpandableCheckboxGroup,
};
